* {
  box-sizing: border-box;
}
@media only screen and (max-width: 5120px) {
  .underConstructionMsg {
    border: 2px solid rgba(36, 101, 161, 0.9);
    font-size: 30px;
    padding: 30px;
    border-radius: 10px;
    background-color: bisque;
  }
  .about-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
    height: 100vh;
    width: 100vw;
  }
  .about-intro {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: left;
    align-items: center;
  }

  .about-intro-text-wrapper {
    margin: 0;
    margin-top: 200px;
    text-align: left;
    margin-left: 150px;
    padding: 10px;
  }

  .typical {
    font-size: 25px;
  }
  .intro-instruction {
    padding-top: 20px;
  }
  .intro-instruction-text {
    font-size: 20px;
  }
  .intro-arrow {
    text-align: center;
    font-size: 30px;
  }

  .about-intro-navigation {
    margin: 0;
    margin-left: 150px;
    margin-top: 200px;
    text-align: left;
    padding: 10px;
  }
  .about-intro-navigation-link {
    font-size: 20px;
    display: block;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all 0.2s ease-in;
  }

  .about-intro-navigation-link:hover {
    color: rgba(36, 101, 161, 0.9);
  }
  .router-link {
    text-decoration: none;
    color: black;
  }

  .about-text .address {
    font-size: 40px;
    color: rgb(0, 0, 0);
  }
  .about-text .intro {
    font-size: 20px;
    color: rgb(0, 0, 0);
  }
  .about-text .details {
    font-size: 10px;
    color: rgb(0, 0, 0);
  }
  .about-animation-container {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .about-container {
    grid-template-columns: none;
    grid-template-rows: 1fr;
    grid-gap: 0;
  }
  .about-intro-navigation {
    margin-left: 20px;
  }
  .about-intro-navigation-link {
    font-size: 30px;
  }
  .about-intro-text-wrapper {
    margin-left: 20px;
  }
  .about-animation-container {
    display: none;
  }
}
