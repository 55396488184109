@media only screen and (max-width: 5120px) {
  .portfolio-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  .portfolio-text-wrapper-bounce {
    margin-top: 60px;
  }
  .portfolio-text-wrapper {
    margin: 0;
    text-align: left;
    padding: 20px;
  }
  .portfolio-heading {
    margin: 0;
    margin-left: 150px;
    text-align: left;
    font-size: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .portfolio-text {
    margin: 0;
    margin-left: 150px;
    text-align: justify;
    line-height: 40px;
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .portfolio-grid {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0;
    margin-right: 100px;
    grid-gap: 20px;
    height: fit-content;
  }
  .portfolio-item-container {
    border-radius: 10px;
    height: 250px;
    width: 250px;
    background-color: rgb(195, 195, 195);
    display: flex;
    align-items: center;
    position: relative;
  }
  .portfolio-item-container img {
    border: 0.7px solid black;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .portfolio-item-img {
    opacity: 0.5;
  }

  .portfolio-item-overlay {
    position: absolute;
    z-index: 2;
    width: 0;
    height: 100%;
    right: 100%;
    background: rgba(254, 177, 82, 0.9);
    border-radius: 10px;
    transition: 0.7s ease;
  }
  .portfolio-item-overlay-content-wrapper {
    /* position: absolute; */
    z-index: 2;
    width: 0;
    height: 100%;
    right: 100%;
    transition: 0.7s ease;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .portfolio-item-container:hover .portfolio-item-overlay {
    width: 100%;
    right: 0;
  }

  .portfolio-item-container:hover .portfolio-item-overlay-content-wrapper {
    width: 100%;
    right: 0;
  }

  .portfolio-item-heading {
    font-size: 25px;
    color: black;
    margin: 0;
  }
  .portfolio-item-text {
    text-align: center;
    font-size: 20px;
    padding: 5px;
    color: black;
  }
  .portfolio-item-text-uidpw {
    margin: 0;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
  }
  .portfolio-item-text-poll {
    margin: 0;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 5px;
  }
  .portfolio-demo-link {
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-weight: 900;
    transition: all 0.3s ease;
    padding: 5px;
  }
  .portfolio-demo-link:hover {
    border-bottom: 2px solid rgb(25, 25, 254);
  }
  .carousel .slide {
    background-color: white;
  }
  .portfolio-mobile-view-bounce {
    display: none;
  }
}

@media (max-width: 768px) {
  .portfolio-heading {
    margin-left: 0;
    font-size: 30px;
  }
  .portfolio-text {
    margin-left: 0;
    font-size: 18px;
  }

  .portfolio-wrapper {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
    align-items: baseline;
    grid-gap: 0;
  }
  .portfolio-mobile-view-bounce {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100vw;
  }
  .portfolio-grid-bounce {
    display: none;
  }
}
