* {
  box-sizing: border-box;
}
@media only screen and (max-width: 5120px) {
  .education-web-view-wrapper {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .education-mobile-view-wrapper {
    display: none;
  }
  .education-text-left-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-left: 150px;
  }
  .education-heading {
    margin: 0;
    font-size: 40px;
    padding: 20px;
  }
  .education-instruction-mobile-view {
    display: none;
  }
  .education-title-wrapper {
    padding: 20px;
    display: flex;
    flex-flow: column;
    width: fit-content;
  }
  .education-title-container {
    text-align: left;
    padding: 20px;
  }
  .education-select-button {
    margin: 5px;
    padding: 5px;
    border: 0.5px solid;
    background: none;
    font-family: inherit;
    /* background: rgba(36, 101, 161, 0.7); */
    /* box-shadow: 2px 2px 2px rgb(125, 125, 125); */
    border-radius: 7px;
  }
  .education-title-text {
    margin: 0;
    padding: 10px;
    font-size: 15px;
    color: rgb(73, 73, 73);
  }
  .education-sub-heading {
    margin: 0;
    padding: 10px;
    font-size: 20px;
  }
  .education-select-button:hover {
    border: 2px solid rgba(36, 101, 161, 0.7);
    background-color: none;
    box-shadow: 2px 2px 2px rgb(125, 125, 125);
  }
  .education-select-button:focus {
    outline: none;
    box-shadow: none;
    /* border: 2px solid rgba(36, 101, 161, 0.7); */
    background: rgba(36, 101, 161, 0.7);
  }
  .education-description-right-wrapper {
    border: 1px solid rgba(36, 101, 161, 0.9);
    border-radius: 10px;
    padding: 20px;
    margin-top: 85px;
    margin-right: 100px;
  }
}

@media (max-width: 768px) {
  .education-web-view-wrapper {
    display: none;
  }
  .education-mobile-view-wrapper {
    height: 100vh;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
  }
  .education-heading {
    padding: 0;
    margin-top: 60px;
    font-size: 30px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .education-sub-heading {
    font-size: 18px;
  }
  .education-instruction-mobile-view {
    display: flex;
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .education-title-wrapper {
    padding: 0;
    padding-left: 10px;
  }
  .education-title-container {
    padding: 10px;
  }
  .education-text-left-container {
    margin: 0;
  }
  .education-description-right-wrapper {
    padding: 7px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
