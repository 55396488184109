* {
  box-sizing: border-box;
}
@media only screen and (max-width: 5120px) {
  .logo {
    font-family: "Kanit", sans-serif;
    margin: 0;
    margin-top: 50px;
    margin-left: 150px;
    margin-bottom: 100px;
    color: rgb(36, 101, 161);
    font-weight: bolder;
    font-size: 30px;
    /* text-align: left; */
    letter-spacing: 0.3em;
  }
  .logo-letter-wrapper {
    display: inline-block;
    position: relative;
    white-space: nowrap;
  }
  .logo-letter-wrapper:after {
    content: "";
    height: 1.3px;
    width: 0%;
    position: absolute;
    top: 50%;
    left: 110%;
    background-color: #000;
    transition: width 500ms, left 500ms;
    transition-property: width, left;
    transition-duration: 500ms, 500ms;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
  }
  .logo-letter-wrapper:hover::after {
    width: 120%;
    left: -10%;
    transition: width 500ms;
  }
  .fixed-header {
    position: fixed;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: rgb(255, 255, 255);
    z-index: 9995;
  }
  #main-header-nav {
    font-size: 18px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  #main-header-nav a {
    padding: 10px;
    text-decoration: none;
    color: rgba(0, 0, 0, 1);
    transition: all 0.3s ease;
    transform: scale(1);
    margin-top: 50px;
    margin-right: 80px;
    margin-bottom: 100px;
  }
  #main-header-nav a:hover {
    transform: scale(1.1);
    color: rgba(36, 101, 161, 0.9);
    font-weight: 600;
  }
  #main-header-nav a:focus {
    outline: none;
  }
  .menu-wrap {
    display: none;
  }
  .header-hamburger-menu {
    border: none;
    background: transparent;
    cursor: pointer;
    display: none;
  }
  .header-hamburger-menu:focus {
    outline: none;
  }
}

@media (max-width: 768px) {
  .fixed-header {
    grid-template-columns: 1fr 1fr;
  }
  .menu-wrap {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
  }
  .menu-wrap .toggler {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
    cursor: pointer;
    width: 23px;
    height: 23px;
    opacity: 0;
  }

  .menu-wrap .hamburger {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 9997;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-wrap .hamburger > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 3px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
  }
  .menu-wrap .hamburger > div::before,
  .menu-wrap .hamburger > div::after {
    content: "";
    position: absolute;
    z-index: 9997;
    top: -8px;
    width: 100%;
    height: 3px;
    border-radius: 1px;
    background: inherit;
  }
  .menu-wrap .hamburger > div::after {
    top: 8px;
  }

  .menu-wrap .toggler:checked + .hamburger > div {
    transform: rotate(135deg);
  }

  .menu-wrap .toggler:checked + .hamburger > div::before,
  .menu-wrap .toggler:checked + .hamburger > div::after {
    top: 0;
    transform: rotate(90deg);
  }
  .menu-wrap .toggler:checked:hover + .hamburger > div {
    transform: rotate(225deg);
  }

  .menu-wrap .toggler:checked ~ .menu {
    visibility: visible;
  }
  .menu-wrap .toggler:checked ~ .menu > div {
    transform: scale(1);
    transition-duration: 0.5s;
  }
  .menu-wrap .toggler:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.1s ease 0.1s;
  }
  .menu-wrap .menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: top;
    justify-content: flex-end;
  }
  .menu-wrap .menu > div {
    background: rgba(36, 101, 161, 0.9);
    height: 100%;
    width: 100%;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
  }
  .menu-wrap .menu > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* padding: 10px; */
    background: rgba(36, 101, 161, 0.9);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .menu-wrap .menu > div > div > ul {
    text-align: center;
    list-style: none;
    font-size: 30px;
    padding: 10px;
  }
  .menu-wrap .menu > div > div > ul > li {
    text-decoration: none;
    list-style: none;
    font-size: 30px;
    padding: 10px;
  }
  .hamburger-router-link {
    text-decoration: none;
    color: black;
  }

  #main-header-nav {
    display: none;
  }
}
