@media only screen and (max-width: 5120px) {
  .footer-wrapper {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: right;
    background-color: rgb(255, 255, 255);
  }

  .footer-link {
    color: gray;
    font-size: 30px;
    margin-right: 30px;
  }
  .footer-credentials {
    text-align: left;
    font-size: 15px;
    color: gray;
    margin-right: 100px;
  }
  .footer-nav {
    margin-right: 200px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .footer-credentials {
    display: none;
  }
  .footer-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  .footer-nav {
    margin: 0 auto;
  }
}
