@media only screen and (max-width: 5120px) {
  .AnimationContainer {
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 100px);
  }
}

@media (max-width: 768px) {
  .AnimationContainer {
    height: 50vh;
  }
}
