*{
    box-sizing: border-box;
}
.experience-description-wrapper{
    display: flex;
    align-items: center;
}
.experience-internal-link{
    display: inline;
    font-size: 20px;
}
.experience-internal-link:hover{
    color: rgba(254, 177, 82, 0.9);
}

@media (max-width:768px){
   
    
}

